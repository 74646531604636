import React, { useEffect, useState } from "react";
import Table from "./components/Table";
import Signin from "./components/Signin";
import dataFile from "./mock.json"
import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setData(dataFile);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AuthContextProvider>
        <Routes>
          <Route path='/' element={<Signin />} />
          <Route
            path='/table'
            element={
              <ProtectedRoute>
                <div class="pl-10 pr-10">
                  <Table mockData={data} />
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
  );
}

export default App;
