export const COLUMNS = [
  {
    Header: "Nom Syndic",
    accessor: "raisonSocialeOuNomSyndic",
  },
  {
    Header: "Commune Syndic",
    accessor: "communeEtablissement",
  },
  {
    Header: "Immatriculation",
    accessor: "numImmatriculationCopropriete",
  },
  {
    Header: "Nom Copro",
    accessor: "nomCopropriete",
  },
  {
    Header: "Adresse Copro",
    accessor: "adressePrincipale",
  },
  {
    Header: "Commune Copro",
    accessor: "commune",
  },
  {
    Header: "Code Postal Copro",
    accessor: "codePostal",
  },
  {
    Header: "Nombre de lots",
    accessor: "nbLots",
  },
  {
    Header: "Type de chauffage",
    accessor: "typeChauffage",
  },
];
