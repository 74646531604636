import { useMemo } from "react";
import { COLUMNS } from "../column";
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination } from "react-table";
import { ColumnFilter } from "./ColumnFilter";
import { GlobalFilter } from "./GlobalFilter";
import '../index.css';
import { UserAuth } from '../context/AuthContext';

import jsPDF from 'jspdf';
import 'jspdf-autotable'

const downloadData = () => {
  var doc = new jsPDF('l', 'pt');
  var elem = document.getElementById('table');
  var data = doc.autoTableHtmlToJson(elem);

  doc.autoTable({html:'#table'});
  doc.setFontSize(10);
  doc.text(280, 580, 'Meilleur Habitat de France - 09.80.80.21.12 - contact@m-h-f.fr');
  doc.save('tableau_pdf.pdf');
};

const downloadExcel = () => {
  var data_type = "data:application/vnd.ms-excel";
  var table_div = document.getElementById('table');
  var table_html = table_div.outerHTML.replace(/ /g, '%20');

  var a = document.createElement('a');
  a.href = data_type + ', ' + table_html;
  a.download = 'tableau_excel.xls';
  a.click();
};

const Table = ({ mockData }) => {
  const columns = useMemo(() => COLUMNS, []); // memoize before adding to useTable hook
  const data = useMemo(() => [...mockData], [mockData]);
  const { user, logout } = UserAuth();
  
  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      alert('Une erreur est survenue lors de la déconnexion')
    }
  };

  // default column component
  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      {/* apply the table props */}
        <div class="overflow-x-auto">
          <div class="py-2 inline-block min-w-full">
            <div class="overflow-hidden ">
              <table id='table' class="border-collapse min-w-full" {...getTableProps()}>
                <thead class="bg-white border-y border-x" >
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props 
                          // <span class="font-normal text-black">{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                          // {...column.getHeaderProps(column.getSortByToggleProps())}
                          <th class="cursor-pointer font-bold text-gray-900 px-6 py-4 text-center border-y border-x ">
                            {column.render("Header")}
                            <div class="font-normal text-left text-sm">{column.canFilter ? column.render("Filter") : null}</div>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              {/* Apply the table body props */}
              <tbody class="border-x" {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row, i) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 text-center" {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return <td class="border-y border-x text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
              </table>
            </div>
          </div>
        </div>

      <div className="pagination" class="text-center py-4">
              <button  class="cursor-pointer bg-gray-100 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded-l" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>{" "}
              <button  class="cursor-pointer bg-gray-100 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded-l" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {"<"}
              </button>{" "}
              <button class="cursor-pointer bg-gray-100 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded-r" onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>{" "}
              <button class=" cursor-pointer bg-gray-100 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded-r" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {">>"}
              </button>{" "}
              <span class="px-2 text-white">
                Page{" "}
                <strong >
                  {pageIndex + 1} sur {pageOptions.length}
                </strong>{" "}
              </span>
              <span class="font-bold text-white">
                | 
              </span>
              <span class="px-2 text-white">
                Aller à la page n° {" "}
                <input class="form-select form-select-lg pl-2 py-0.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "50px" }}
                />
              </span>{" "}
              <select class="cursor-pointer form-select form-select-lg px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 30, 50, 100, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} colonnes affichées
                  </option>
                ))}
              </select>
              <button className="border border-blue-500 bg-blue-600 hover:bg-blue-500 w-40 px-2 py-1 ml-3 text-white rounded-lg text-sm font-normal" role="menuitem" tabindex="-1" id="user-menu-item-2" onClick={handleLogout} >Déconnexion</button>
            
              <button className="border border-blue-500 bg-blue-600 hover:bg-blue-500 w-40 px-2 py-1 ml-3 text-white rounded-lg text-sm font-normal" role="menuitem" tabindex="-1" id="user-menu-item-2" onClick={downloadData}>
                Télécharger en PDF
              </button>

              <button className="border border-blue-500 bg-blue-600 hover:bg-blue-500 w-40 px-2 py-1 ml-3 text-white rounded-lg text-sm font-normal" role="menuitem" tabindex="-1" id="user-menu-item-2" onClick={downloadExcel}>
                Télécharger en Excel
              </button>
            
            </div>
    </>
  );
};

export default Table;