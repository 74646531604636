export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <span>
      {" "}
      <input class="w-25 mx-auto form-control block px-2 py-1.5 mt-2 mb-1 text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        placeholder={`Recherche colonne`}
      />
    </span>
  );
};
